<template>
  <tr>
      <WiListDataTableActions
        v-if="config.mode == 'select'"
        :config="config"
        :props="props"
      ></WiListDataTableActions>
      <td v-else class="text-xs-center" style="padding: 0px !important; min-width: 70px;">
        <v-tooltip bottom>
          <span slot="activator">
            <v-icon outlined v-ripple  :color="$wiDark ? 'white' : 'blue'" @click="openTicket(props.item.id)" style="cursor: pointer;">info</v-icon>
            <router-link :to="{name: 'chamado', params: {id: props.item.id}}" target="_blank" style="text-decoration: none;">
              <v-icon outlined v-ripple  :color="$wiDark ? 'white' : 'blue'">open_in_new</v-icon>
            </router-link>
          </span>
          <span>
            Detalhes deste item
          </span>
        </v-tooltip>
      </td>
      <td class="text-xs-center" style="padding: 0 10px !important; font-size: 18px;">
        <strong>#{{ props.item.id }}</strong>
      </td>
      <td class="text-xs-center" style="padding: 0 10px !important;">
        <strong>{{ props.item.person.name | stringlength(40) }}</strong> 
      </td>
      <td class="text-xs-center" style="padding: 0 10px !important;">
        <strong v-if="props.item.assunto_chamado">
          {{ props.item.assunto_chamado | stringlength(40) }}
        </strong>
        <strong v-else>- - -</strong>
      </td>
      <td class="text-xs-center" style="padding: 0 10px !important;">
        <v-icon large :style="{color: props.item.status.color}">{{props.item.status.icon}}</v-icon><br>
        <strong>{{ props.item.status.name }}</strong> <span v-if="props.item.agendamento_log_format"> até <span :style="{color: props.item.agendamento_log_dias < 0 ? '#c90000' : (props.item.agendamento_log_dias == 0) ? '#e59100' : 'blue'}">{{props.item.agendamento_log_format}}</span> </span>
      </td>
      <td class="text-xs-left" style="padding: 0 10px !important; max-width: 150px;">
        <textarea v-if="props.item.network_names" rows="3" cols="20" textarea v-model="props.item.network_names"></textarea>
        <span v-else>Nenhuma rede vinculada.</span>
      </td>
      <td class="text-xs-center" style="padding: 0 10px !important;">
        <v-icon :style="{color: props.item.category.color}">{{props.item.category.icon}}</v-icon><br>
        <strong>{{ props.item.category.name }}</strong>
        {{ formatCategoryParent(props.item.category.parents, 'name') }}
      </td>
      <td class="text-xs-center" style="padding: 0 10px !important; max-width: 150px;">
        <textarea :style="{color: props.item.last_event_seconds > 7200 && props.item.stage == 1 ? (props.item.last_event_seconds > 14400 && props.item.stage == 1 ? '#b71c1c' : '#e59100') : ''}" style="font-weight: bold; max-width: 140px;" v-if="props.item.last_note" rows="3" cols="20" textarea v-model="props.item.last_note.description_time" ></textarea>
        <textarea :style="{color: props.item.last_event_seconds > 7200 && props.item.stage == 1 ? (props.item.last_event_seconds > 14400 && props.item.stage == 1 ? '#b71c1c' : '#e59100') : ''}" style="font-weight: bold; max-width: 140px;" v-else rows="3" cols="20" textarea v-model="props.item.description_time" ></textarea>
      </td>
      <td class="text-xs-center" style="padding: 0 10px !important;">
        {{ props.item.team.name }}
      </td>
      <td class="text-xs-center" style="padding: 0 10px !important;">
        <span v-if="props.item.responsible && props.item.responsible.name">{{ props.item.responsible.name || 'Não Definido' }}</span>
        <span v-else>Não Definido</span>
      </td>
    </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'Tickets',
    methods: {
      openTicket: function (ticketId) {
        this.$router.push({name: 'ticket', params: {id: ticketId}})
      },
      formatCategoryParent: function (items, index) {
        return ' << ' + items.map(item => (item[index])).join(' << ')
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
</style>